* { 
    box-sizing: border-box;
}

html,
body {
    width: 100%;
    height: 100%;
}

body {
    font-family: $regular-font;
    font-size: $regular-text-size;
    color: $text-colour;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    font-smoothing: antialiased;
}

a {
    color: $link-colour;
    text-decoration: none;

    &:hover {
        color: $link-colour-over;
    }
}