// .about
$about-width: 450px !default;

// .about-body
$about-body-width: 295px !default;

// .about-body-description
$about-body-description-line-height: $caption-text-size + 4px !default;

// .about-avatar
$about-avatar-shrunk-width: 70px !default;
$about-avatar-shrunk-height: $about-avatar-shrunk-width !default;