#ribbon {
    background-image: url(/img/banner.jpg);
    background-size: cover;
    width: 100%;
    height: 10px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
}

#wrap {
    margin: 0 auto;
    width: 885px;

    // Sticky footer trick, see http://css-tricks.com/snippets/css/sticky-footer/
    min-height: 100%;
    margin-bottom: (-1 * $footer-height);

    &:after {
      content: "";
      display: block;
      height: $footer-height;
    }
}

@media only screen and (max-width : 900px) {
    #wrap { width: 90%; }
}

header {
    transition-property: margin padding;
    transition-duration: 0.3s;
    transition-timing-function: ease-in-out;
    margin: 0 0 40px;
    padding-top: 50px; // 40px margin [has to be padding for sticky footer] + 10px for ribbon [positioned absolutely]
    padding-bottom: 10px;
    border-bottom: 1px solid $border-colour;

    a,
    a:hover {
        color: $text-colour;
    }
}

@media only screen and (max-width : 480px) {
    header {
        padding-top: 30px;
        margin: 0 0 20px;
    }
}

h1 {
    font-family: $title-font;
    font-size: $regular-text-size;
    font-weight: bold;
    margin: 0 0 5px;
    text-transform: uppercase;
    letter-spacing: 1px;
}

h2 {
    font-family: $subtitle-font;
    font-size: $regular-text-size;
    margin: 0;
    color: $subtitle-colour;
    font-weight: normal;
}

footer {
    background-image: url(/img/banner.jpg);
    background-size: cover;
    height: $footer-height;
    width: 100%;
    display: table;

    a,
    a:hover {
        color: $footer-link-colour;
    }
}
